function ChapterFour() {
  return (
    <>
      <p>
        In my spare time, I like to work on side projects, learn new technologies and generally keep my skills in shape. However, that usually requires sitting additional hours in front of the screen, which can be taxing. To combat that with some fresh O<sub>2</sub>, I enjoy taking my mountain bike for a ride in the neighboring forest, or playing tennis or basketball.
      </p>
      <p>
        I love the outdoors, so I take every chance to spend some time travelling with my wife and discover new places, looking out for hiking trails, mountains, rivers, cute villages, or anywhere else where there is enough greenery, and pine trees!</p>
      <p>
        Back inside, I am also a big fan of music, and when no one is around, I take enough time to listen to some devastating metal music, discovering new bands, and playing guitar; though I do actively appreciate many other musical genres. And if you were wondering ... video games are now merely a thing of the past, albeit a beautiful one.
      </p>
    </>
  );
}

export default ChapterFour;
import {useState, useRef, useEffect} from 'react';
import "./typescreen.styles.css";

function TypeScreen() {
  const [char, setChar] = useState("");
  // const isMounted = useRef(false);
  const text = 
`fuunction sayHello() {
  console.log(
    "WELCOME TO VLAD'S PERSONAL WEB"
  );
}`;

  const screenType = () => {
    let i = 0;
    const iid = setInterval(() => {
      if (i === text.length - 1) {
        return iid;
      }
      setChar(prev => prev + text[i]);
      i++;
    }, 25);
  };

  useEffect(() => {
    // if (!isMounted.current) {
    //   isMounted.current = true;
    //   return;
    // }
    const iid = screenType();
    return () => clearInterval(iid);
  }, []);
  
  return (
    <div className="screen">
      <pre>        
        {char}
      </pre>
    </div>
  );
}

export default TypeScreen;
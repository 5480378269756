// libraries
import { useState } from "react";

// components
import Hero from "../../components/hero/hero";
import Footer from "../../components/footer/footer";
import Modal from "../../components/modal/modal";
import Colophon from "../../components/modal/colophon";

// assets
import ListasAppImage from "../../assets/images/siteimg-listas-app.png";
import BreakOutImage from "../../assets/images/siteimg-breakout-app.png";
import MusicPlayerImage from "../../assets/images/siteimg-muziqly-app.png";
import Lakeside from "../../assets/images/vectors/island-2.svg";

// styles
import "./projects.styles.css";

function Projects() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  function setModal() {
    setIsModalOpen(!isModalOpen);
  }

  return (
    <>
      <Hero title="Playground" vector={Lakeside} />
      <main>
        <div className="presentation animateFall">
          <h1>Side Projects</h1>
          <p>In addition to my main line of work, I like to explore new ideas and experiment with different technologies. My side projects range from personal challenges to potentially useful applications, showcasing my ability to take initiative, solve problems, and expand my skill set. Each of these projects reflect my desire to continuously learn and build solutions that are practical in some way. Have a look at some of my favorite side projects down below.
          </p>
          <hr />
          {/* PROJECT */}
          <article className="project-container mt-md">
            <div className="project-container__image mb-md">
              <img src={ListasAppImage} alt="project screenshot" />
            </div>
            <h2>Listas &mdash; grocery shopping organizer</h2>
            <p>
              The idea for building this application was initiated by my wife. Each time we would go grocery shopping, we would dilligently jot down the shopping list on a piece of paper. However, often we'd find that upon arriving at the grocery store, the shopping list was not there with us.</p>
            <p>
              The goal of this exercise to build a full stack CRUD application with front-end, back-end, as well storage components. It allows users to create, and manage their shopping lists on both desktop and mobile browsers. The front-end was built using vanilla JavaScript, while the back-end is handled with PHP, including server-side authentication. The database used on this project is MySQL
            </p>
            <a className="cta-link cta-action"
              href="https://listasapp.vladislavderetic.com"
              target="_blank">
              View project
              <span className="material-symbols-outlined light">
                arrow_outward
              </span>
            </a>
          </article>
          {/* PROJECT */}
          <article className="project-container mt-l">
            <div className="project-container__image mb-md">
              <img src={BreakOutImage} alt="project screenshot" />
            </div>
            <h2>Breakout &mdash; remake of an old classic</h2>
            <p>
              When I started learning to code, I set myself up with a challenge to develop at least one video game. I decided to start by building a remake of an all time classic &mdash; the Breakout &mdash; built entirely using vanilla JavaScript, and the Canvas API.
            </p>
            <p>
              The player controls the paddle movement using keyboard, and progresses through a few levels of increasing difficulty. Optionally, the player can enable a background music (which I thought was quite fitting). Overall, a fun little project, which not only introduced me to a new API, but also to a different way of thinking when writing code, and aspects that are unique to video games.
            </p>
            <p></p>
            <a className="cta-link cta-action"
              href="https://vladislavderetic.com/apps/breakout"
              target="_blank">
              View project
              <span className="material-symbols-outlined light">
                arrow_outward
              </span>
            </a>
          </article>
          {/* PROJECT */}
          <article className="project-container mt-l">
            <div className="project-container__image mb-md">
              <img src={MusicPlayerImage} alt="project screenshot" />
            </div>
            <h2>Muziqly &mdash; a small music player</h2>
            <p>This little app goes back to the beginnings when I only started learning to code. It was one of those classic projects to practice the skills essential on the front-end, and play around with the APIs for manupulating audio.</p>
            <p>I did not want to just create a functional music player, however. The app also allows users to switch between playlists, search for songs, and apply some visual effects. It was built using HTML, CSS, JavaScript, and local storage</p>
            <a className="cta-link cta-action"
              href="https://vladislavderetic.com/apps/music_player"
              target="_blank">
              View project
              <span className="material-symbols-outlined light">
                arrow_outward
              </span>
            </a>
          </article>
        </div>        
      </main>
      <Footer setModalFunction={setModal} />
      {isModalOpen && (
        <Modal 
          setModalFunction={setModal}
          content={Colophon}
        />
      )}
    </>
  );
}

export default Projects;
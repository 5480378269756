// libraries
import { useState } from "react";

// components
import HomeHeader from "./header";
import FactSticker from "../../components/factsticker/factsticker";
import Footer from "../../components/footer/footer";
import Modal from "../../components/modal/modal";
import Colophon from "../../components/modal/colophon";

// home: activity assets
import LMS from "../../assets/images/vectors/home/lms-vector.svg";
import KanbanBoard from "../../assets/images/vectors/home/kanban-vector.svg";
import Automation from "../../assets/images/vectors/home/automation-vector.svg";
import "../../assets/images/vectors/home/dashed-connector.svg"; // used in css

// home: fun fact icons
import GamepadIcon from "../../assets/images/vectors/home/icon-gamepad.svg";
import BottleIcon from "../../assets/images/vectors/home/icon-bottle.svg";
import BookIcon from "../../assets/images/vectors/home/icon-book.svg";
import CezveIcon from "../../assets/images/vectors/home/icon-cezve.svg";
import ShirtIcon from "../../assets/images/vectors/home/icon-shirt.svg";
import CevapIcon from "../../assets/images/vectors/home/icon-cevapi.svg";

// styles
import "./home.styles.css";

function Home() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  function setModal() {
    setIsModalOpen(!isModalOpen);
  }

  return (
    <>      
      <HomeHeader />
      <section className="section-home">
        <main>
          <div className="section-home__intro">
            <h1>
              Hi! I'm Vlad
            </h1>
            <h2>
              I tinker with educational technologies by day, 
              and build web applications by night, unless...
            </h2>
          </div>

          {/* ACTIVITY DESCRIPTIONS */}
          <article className="flex-container">
            <div>
              <img src={LMS} alt="learning management system" />
            </div>
            <div>
              <div className="article-icon__container">
                <i className="article-icon"></i><span>EduTech</span>
              </div>
              <h2>Provision of SaaS solutions in Higher and Corporate Education</h2>
              <p>
                ...I am helping institutions deliver their education online by leading the setup, deployment, and maintenance of intuitive and engaging learning experiences.
              </p>
            </div>
          </article>

          <article className="flex-container">
            <div>
              <div className="article-icon__container">
                <i className="article-icon"></i><span>Product</span>
              </div>
              <h2>Product Design, Development and Implementation</h2>
              <p>
                ...I am working with engineering teams to drive the product development process, bringing innovative solutions to online education.
              </p>
            </div>
            <div>
              <img src={KanbanBoard} alt="kanban board" />
            </div>
          </article>

          <article className="flex-container">
            <img src={Automation} alt="business automation process" />
            <div>
              <div className="article-icon__container">
                <i className="article-icon"></i><span>Automate</span>
              </div>
              <h2>Business Process Automation and Analysis</h2>
              <p>
                ...I am busy optimizing labor-intensive and repetitive tasks, using code or various automation tools.
              </p>
            </div>
          </article>

          <h2>Work in a Nutshell</h2>
          <p>
            My work fits me into a position that intersects Technology, People, and Design, all brought together in bringing innovative solutions to a wide spectrum of users. I have a natural passion for learning &mdash; mainly, but not limited to, technologies and tools for crafting clean user experiences. Over time, it allowed me to wear many hats during my career, including front-end development and design, workflow automation, and project management.
          </p>
          
          {/* FUN FACTS */}
          <h2>A Few Fun Facts</h2>
          <div className="facts__wrapper">
            <FactSticker
              icon={GamepadIcon}
              text="A video game got me into programming"
            />
             <FactSticker
              icon={BottleIcon}
              text="Taste of milk makes me a bit unwell"
            />
            <FactSticker
              icon={BookIcon}
              text="I am a lifelong learner"
            />
            <FactSticker
              icon={CezveIcon}
              text="I make my coffee in cezve pot"
            />
            <FactSticker
              icon={CevapIcon}
              text="My favorite food is ćevapi"
            />
            <FactSticker
              icon={ShirtIcon}
              text="My sense of fashion is ≤ 3 colors"
            />
          </div>
        </main>
      </section>
      <Footer setModalFunction={setModal} />
      {isModalOpen && (
        <Modal 
          setModalFunction={setModal}
          content={Colophon}
        />
      )}
    </>
  );
}

export default Home;
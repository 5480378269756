function ChapterTwo(props) {
  return (
    <div className={`chapter 
      ${props.page === "previous" ? "previous" : ""}`}>
      <h2>Chapter II: Time to Optimize Time</h2>
      <p>
        It just so happened that I came across a video game, which became
        notorious for being very challenging and difficult to complete.
        Videos of raging gamers, keyboards without keys, and flying coffee cups
        were all over Youtube, and I thought ... challenge accepted!
        And so after many hours of infuriatingly frustrating but incredibly
        rewarding experience, the game was finally completed. However, the real
        takeaway from this experience was its educational value, in that there is
        a significant return on investment into tackling challenges, if persistence
        is strong enough. Namely, my sense of accomplishment was directly
        proportional to the degree of complexity of a given challenge.</p>
        
        <p>That experience opened new ways of thinking for me in approaching challenges,
        and that's when I started to think of programming as a challenge. That
        is what got me back to coding; although this time equipped with a new
        mindset, and rather than rushing back to C++, I started small &mdash; with
        HTML, CSS and JavaScript
      </p>
      <p>
        Shortly after, I was hired by an e-learning company, Interactive Pro, to manage their online
        learning platform and a set of products for a client. Learning those front-end
        skills became instrumental in delivering series of UX enhancements that helped
        boost customer satisfaction scores. I worked with a team of engineers on projects
        such as deploying automated video transcription service for more accessible online
        lectures, or integrating a popular web conferencing tool (Zoom) into the platform,
        enabling faculty and learners to interact in real time during seminars. Our projects
        were received with praise from the client, and I felt encouraged to keep learning.
      </p>
    </div>
  );
}

export default ChapterTwo;
// assets
import { useEffect } from "react";
import VladPhoto from "../../assets/images/vlad_photo.png";

function Intro(props) {

  function handleMessage(e) {
    e.preventDefault();
    props.setMsgClickState();    
  }

  return (
    <article className="intro animateFall">
      <h1>Who did this?</h1>
      <p>I am passionate about technology and learning &mdash; a common demoninator behind my work. I like to think of work as a footprint that we leave behind, lingering there for others to see. That is what pushes me to excel, and continuously improve on my work &mdash; delivering experiences that are compelling and user-centric. You can read more about my journey down below.</p>
      <hr />
      <div className="flex-container mb-md mt-md">
        <div className="author__photo mr-md animateFall">
          <img src={VladPhoto} alt="Vlad" />
        </div>
        <div className="author__intro">
          <h2 className="animateFall">I'm Vlad. Working remotely from Prague, Czech Republic</h2>
          <p className="animateFall">Over the past 7+ years I have been on the frontlines
            of bringing technology and the world of education closer together.           
          </p>
          <p className="animateFall">Nowadays, I focus on enabling clients to expand or transition their
            learning environments into SaaS solutions as a Senior Manager for
            Technological Strategy at FutureLearn.
          </p>
        </div>
      </div>
      <div className="flex-container mb-md">
        <div className="author__bio mr-md">
          <a href={window.location.origin + "/docs/vladislav_deretic_resume.pdf"}
            className="cta-link cta-action"
            target="_blank">
              Download CV
          </a>
        </div>
        <div className="author__contact">
          <a className="cta-link cta-social"
            href="https://www.linkedin.com/in/vladislavderetic"
            rel="noreferrer" target="_blank" title="LinkedIn">
            <i className="devicon-linkedin-plain"></i>
          </a>
          <a className="cta-link cta-social"
            href="https://github.com/Vladcp6uH"
            rel="noreferrer" target="_blank" title="GitHub">
            <i className="devicon-github-original"></i>
          </a>
          <a
            onClick={handleMessage}
            href="#"
            className="cta-link cta-social">
              Message me
          </a>
        </div>
      </div>
      <hr />
    </article>
  );
}

export default Intro;
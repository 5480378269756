function ChapterOne(props) {
  return (
    <div className={`chapter 
      ${props.page === "previous" ? "previous" : ""}`}>
      <h2>Chapter I: The Beginnings</h2>
      <p>
        Ever since my early days, I was hard set on the idea that my life
        should revolve around computers, but little had I known what a rocky
        road it would be. After high school, I never imagined I would ever
        cross paths with education industry during my career, because at
        the time, I was too busy playing video games.
      </p>
      <p>
        After graduating from university with a degree in economics, I realized
        that playing video games wasn't exactly going to pay the bills. But making
        them certainly could, and I became obsessed about developing a game of
        my own. So I went in full of enthusiasm and got myself a book on C++,
        which, as it turned out fairly quickly, may have not been the happiest choice
        of my first programming language. My game developer ambitions were gone
        with the wind, but no matter how discouraging it felt at the time, I did not
        want to give up on the idea of learning to code. But more on that in a moment...
      </p>
      <p>
        Meanwhile, I made my way into a number of jobs, from an event
        coordinator for a small non-profit organization, over to a database
        administrator at a UK-based education provider, where I quickly grew
        to leading my own team, and got tasked with expanding the business into the
        U.S. market. I was sent to Chicago, Illinois to spend some time on client premises, where
        I developed a successful admissions framework for international
        student recruitment, which enabled a near instant spike in sales volume.
        This was early 2016 and it was exactly that time, which marked a
        turning point in my career with an important shift in mindset
        that stuck with me to this day. Ironically, a video game was to blame.
      </p>
    </div>
  );
}

export default ChapterOne;
import { useState, useEffect } from "react";

// styles
import "./header.styles.css";

// components
import TypeScreen from "../../components/typescreen/typescreen.component";
import AnimatedGraphs from "../../components/animatedgraphs/animatedgraphs.component";

// header assets
import Platform from "../../assets/images/vectors/home/platform/platform.svg";
import Apps from "../../assets/images/vectors/home/platform/apps.svg";
import App from "../../assets/images/vectors/home/platform/app-play.svg";
import LargeMessage from "../../assets/images/vectors/home/platform/message_large.svg";
import SmallMessage from "../../assets/images/vectors/home/platform/message_small.svg";
import LargeColumn from "../../assets/images/vectors/home/platform/column_large.svg";
import MediumColumn from "../../assets/images/vectors/home/platform/column_medium.svg";
import SmallColumn from "../../assets/images/vectors/home/platform/column_small.svg";

function HomeHeader() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const scrollToIntro = (e) => {
    e.preventDefault();
    window.scrollTo(
      {
        top: window.innerHeight,
        left: 0,
        behavior: "smooth"
      }
    );
  }

  const detectScreenWidth = () => {
    setScreenWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', detectScreenWidth);
    // unsubscribe event listener
    return () => window.removeEventListener('resize', detectScreenWidth);
  }, [screenWidth]);

  return (
    <header className="hero__home">
      <article className="hero__container">
        <div className="hero__welcome animateFadeIn">
          <h1>
            A chronic perfectionist and tech geek
          </h1>
          <p>
            Currently transforming an education business with data, code, some planning, a bit of intuition, and a pinch of secret sauce
          </p>
          <a 
            href=""
            onClick={(e) => scrollToIntro(e)}
            className="cta-link cta-action">
              {screenWidth < 991 ? "sayHello()" : "SAY HELLO"}
          </a>
          {/* display only on moblie and tablets below 991px */}
          {(screenWidth > 374 && screenWidth < 991) && <TypeScreen />}
        </div>
        <div className="hero__graphic">
          <div className="hero__graphic--platform">
            <img className="vector__platform" src={Platform} alt="development platform" />
            <img className="vector__menu" src={Apps} alt="apps menu" />
            <img className="vector__app" src={App} alt="single app" />
            <img className="vector__lmessage" src={LargeMessage} alt="large message bubble" />
            <img className="vector__smessage" src={SmallMessage} alt="small message bubble" />
            <img className="vector__lcolumn" src={LargeColumn} alt="large column chart" />
            <img className="vector__mcolumn" src={MediumColumn} alt="medium column chart" />
            <img className="vector__scolumn" src={SmallColumn} alt="small column chart" />
            <AnimatedGraphs />
          </div>
        </div>
      </article>
    </header>
  );
}

export default HomeHeader;
function Colophon(props) {
  return (
    <section className="modal-dialogue">
      <a onClick={props.closefn}
        className="cta-link cta-social" href="#">
        <span className="material-symbols-outlined">close</span>
      </a>
      <h2>This site was built with following technology:</h2>
      <div className="modal-dialogue__item">
        <div className="modal-dialogue__item--icon">
          <i className="devicon-googlecloud-plain"></i>
        </div>
        <div className="modal-dialogue__item--description">
          Inconsolata font for typography
        </div>
      </div>
      <div className="modal-dialogue__item">
        <div className="modal-dialogue__item--icon">
          <i className="devicon-figma-plain"></i>
        </div>
        <div className="modal-dialogue__item--description">
          Figma for vector graphics
        </div>
      </div>
      <div className="modal-dialogue__item">
        <div className="modal-dialogue__item--icon">
          <i className="devicon-react-original"></i>
        </div>
        <div className="modal-dialogue__item--description">
          ReactJS for code
        </div>
      </div>
      <div className="modal-dialogue__item">
        <div className="modal-dialogue__item--icon">
          <i className="devicon-github-original"></i>
        </div>
        <div className="modal-dialogue__item--description">
          GitHub for version control
        </div>
      </div>
    </section>
  );
}

export default Colophon;
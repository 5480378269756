// libraries
import { useState } from "react";

// components
import Hero from "../../components/hero/hero";
import ItemCard from "../../components/itemcard/itemcard";
import Footer from "../../components/footer/footer";
import Modal from "../../components/modal/modal";
import Colophon from "../../components/modal/colophon";

// assets
import MountainPass from "../../assets/images/vectors/island-1.svg";

function Tools() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  function setModal() {
    setIsModalOpen(!isModalOpen);
  }

  return (
    <>    
      <Hero title="Tools" vector={MountainPass} />
      <main>
        <div className="presentation animateFall">
          <h1>Tools</h1>
          <p>I work with a variety of technologies to build engaging and scalable experiences, including front-end, back-end, prototyping tools, as well as popular frameworks. Even though I tend to use most of these in my side projects, I consistently look for every excuse to leverage them at my workplace.</p>
          <hr />
          <div className="flex-container mt-md">
            <ItemCard content={
              {
                iconType: 'devicons',
                iconText: 'devicon-javascript-plain',
                text: 'JavaScript'
              }}
            />
            <ItemCard content={
              {
                iconType: 'devicons',
                iconText: 'devicon-react-original',
                text: 'React'
              }}
            />
            <ItemCard content={
              {
                iconType: 'devicons',
                iconText: 'devicon-php-plain',
                text: 'PHP'
              }}
            />
            <ItemCard content={
              {
                iconType: 'devicons',
                iconText: 'devicon-mysql-plain',
                text: 'MySQL'
              }}
            />
          </div>
          <div className="flex-container mt-md">
          <ItemCard content={
              {
                iconType: 'devicons',
                iconText: 'devicon-firebase-plain',
                text: 'Firebase'
              }}
            />
            <ItemCard content={
              {
                iconType: 'devicons',
                iconText: 'devicon-git-plain',
                text: 'Git'
              }}
            />
            <ItemCard content={
              {
                iconType: 'devicons',
                iconText: 'devicon-figma-plain',
                text: 'Figma'
              }}
            />
            <ItemCard content={
              {
                iconType: 'devicons',
                iconText: 'devicon-vscode-plain',
                text: 'VSCode'
              }}
            />
          </div>
        </div>
      </main>
      <Footer setModalFunction={setModal} />
      {isModalOpen && (
        <Modal 
          setModalFunction={setModal}
          content={Colophon}
        />
      )}
    </>
  );
}

export default Tools;
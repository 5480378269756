// libraries
import { useState, useRef } from "react";

// components
import Hero from "../../components/hero/hero";
import ImageDivider from "../../components/imagedivider/imagedivider";
import Intro from "./intro";
import ChapterOne from "./chapterone";
import ChapterTwo from "./chaptertwo";
import ChapterThree from "./chapterthree";
import ChapterFour from "./chapterfour";

import Footer from "../../components/footer/footer";
import Modal from "../../components/modal/modal";
import Colophon from "../../components/modal/colophon";
import ContactForm from "../../components/modal/contactform";

// assets
import ToscanyHills from "../../assets/images/vectors/island-3.svg";
import Book from "../../assets/images/vectors/about/book-toscany-countryside.svg";
import Excalibur from "../../assets/images/vectors/about/sword-in-a-rock.svg";
import MountainCabin from "../../assets/images/vectors/about/mountain-cabin.svg";
import Castle from "../../assets/images/vectors/about/castle-in-the-woods.svg";

import SaintMichelPhoto from "../../assets/images/photos/about-msm-f.jpg";
import RondaPhoto from "../../assets/images/photos/about-r-s.jpg";
import SaintMaloPhoto from "../../assets/images/photos/about-sm-f.jpg";
import UvacPhoto from "../../assets/images/photos/about-u-s.jpg";

// styles
import "./about.styles.css";

function About() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMessageClicked, setIsMessageClicked] = useState(false);
  const [storySlideNumber, setStorySlideNumber] = useState(1);
  const [chapterPage, setChapterPage] = useState("");
  const imageDivider = useRef(null);
  const countOfChapters = 3;

  function setModal() {
    setIsModalOpen(!isModalOpen);
  }

  function scrollOnSlideChange() {
    const body = document.body.getBoundingClientRect();
    const divider = imageDivider.current.getBoundingClientRect();    
    // scroll up to the slide beginning
    window.scrollTo({
      // 70 -> px height of navbar
      top: divider.bottom - body.top - 70,
      left: 0,
      behavior: 'smooth'
    });        
  }

  function showNextSlide(e) {
    e.preventDefault();
    if (storySlideNumber === countOfChapters) {
      // reset slide to first page
      setStorySlideNumber(1);
      return;
    }    
    // scroll up to slide beginning
    scrollOnSlideChange();
    // update state
    setStorySlideNumber(prev => prev + 1);
    setChapterPage("next")
  }

  function showPreviousSlide(e) {  
    e.preventDefault();
    if (storySlideNumber === 1) {
      // reset slide to first page
      setStorySlideNumber(countOfChapters);
      return;
    }
    // scroll up to slide beginning
    scrollOnSlideChange();
    // update state
    setStorySlideNumber(prev => prev - 1);
    setChapterPage("previous");
  }

  return (
    <>
      <Hero title="About" vector={ToscanyHills} />
      <main>
        {/* Introduction and Contact */}
        <Intro
          setMsgClickState={() => setIsMessageClicked(!isMessageClicked)}
        />
        <h2 className="mt-l">How it all started</h2>
        <p ref={imageDivider}>
        Did I mention that I really enjoy fiddling with technologies for crafting cool and engaging experiences? Well, that wasn't always the case. But let me start from the beginning...</p>

        {/* Slide Chapters */}
        {(() => {
          switch (storySlideNumber) {            
            case 2:
              return (
                <>
                  <ImageDivider vector={Excalibur} descr="sword in a rock" />
                  <ChapterTwo page={chapterPage} />
                </>
              );
            case 3:
              return (
                <>
                  <ImageDivider vector={Castle} descr="castle in the woods"/>
                  <ChapterThree page={chapterPage} />
                </>
              );
            case 1:
            default:
              return (
                <>
                  <ImageDivider vector={Book} descr="book in Toscan countryside"/>
                  <ChapterOne page={chapterPage} />
                </>
              );
          }
        })()}

        {/* Slide Control */}
        <div className="pagecontrol-container">
          <button
            onClick={showPreviousSlide}
            disabled={storySlideNumber === 1 ? true : false}
            className="cta-link cta-browse">
              <span className="material-symbols-outlined">  arrow_left_alt
              </span>
          </button>
          <span className="pagecontrol__pagination">
            {storySlideNumber} / {countOfChapters}
          </span>
          <button
            onClick={showNextSlide}
            disabled={storySlideNumber === countOfChapters ? true : false}
            className="cta-link cta-browse">
              <span className="material-symbols-outlined">  arrow_right_alt                
              </span>
          </button>
        </div>

        {/* Hobbies */}
        <h2>Time outside work</h2>      
        <ImageDivider vector={MountainCabin} descr="log cabin in the mountains" />
        <ChapterFour />

        {/* Gallery */}
        <h2>Some memorable places</h2>
        <div className="gallery__wrapper">
          <div className="gallery__frame">
            <img src={UvacPhoto} alt="serbian countryside" />
          </div>
          <div className="gallery__frame">
            <img src={SaintMichelPhoto} alt="french castle on the shore" />
          </div>
          <div className="gallery__frame">
            <img src={RondaPhoto} alt="rural town in spain on the rocks" />
          </div>
          <div className="gallery__frame">
            <img src={SaintMaloPhoto} alt="path leading to french medieval town" />
          </div>
        </div>
      </main>

      {/* Footer and Modal Control */}
      <Footer setModalFunction={setModal} />
      {isModalOpen && (
        <Modal 
          setModalFunction={setModal}
          content={Colophon}
        />
      )}
      {isMessageClicked && (        
        <Modal
          setModalFunction={() => setIsMessageClicked(!isMessageClicked)}
          content={ContactForm}
        />
      )}
    </>
  );
}

export default About;
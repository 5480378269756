// styles
import "./imagedivider.styles.css";

function ImageDivider(props) {
  return (
    <div className="image__divider mb-md">
      <img src={props.vector} alt={`vector graphic of a ${props.descr}`} />
    </div>
  );
}

export default ImageDivider;
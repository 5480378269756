import "./animatedgraphs.styles.css";

function AnimatedGraphs() {
  return (
    <div className="graph__container">
      <div className="graph__pie"></div>
      <div className="graph__bar-container">
        <div className="graph__bar">
          <div className="left"></div>
          <div className="center"></div>
          <div className="right"></div>
        </div>
        <div className="graph__bar">
          <div className="left"></div>
          <div className="center"></div>
          <div className="right"></div>
        </div>
        <div className="graph__bar">
          <div className="left"></div>
          <div className="center"></div>
          <div className="right"></div>
        </div>
      </div>
    </div>
  );
}

export default AnimatedGraphs;
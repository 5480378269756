function ChapterThree(props) {
  return (
    <div className={`chapter 
      ${props.page === "previous" ? "previous" : ""}`}>
      <h2>Chapter III: Moving Forward</h2>
      <p>
        Given that the online learning platform was an open-source project, I became
        interested in the codebase, and trying to understand how it all really worked.
        I started digging into the API documentation, which not only sparked my
        interest in back-end development, it also began generating ideas about
        enhancements that we could implement, mainly around reporting functionality.
        At that time, we have onboarded a new client &mdash; a renowned British newspaper,
        for whom I managed the delivery of a subscription-based learning portal for
        their readers. This project in particular intrigued me enough to start tinkering
        with PHP in my spare time.
      </p>
      <p>
        I was also privileged to have worked with some wonderful people on an
        experimental project of bringing virtual reality to online education.
        This was a unique experience, in which we collaborated with a VR studio to
        develop a virtual reality module for Facebook&apos;s Oculus Go headsets. These
        headsets, loaded with the learning module were an essential part of the curriculum.
      </p>
      <p>
        And then, COVID-19 hit hard, and online education industry exploded! We needed to
        adapt quickly and transition a lot of clients, who delivered their programmes
        traditionally in classrooms, over to an online delivery. And that is when
        I have been put in charge of overseeing the setup and maintenance of an online
        learning system for the entire business group, spanning over 15 clients.
      </p>
    </div>
  );
}

export default ChapterThree;